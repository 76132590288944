@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
html {
  overflow-x: hidden;
  scroll-behavior: smooth ;
}
.loading {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.loading.hidden {
  opacity: 0;
}
img{
  width: 100%;
  height: 100%;
}
:root {
  --pink: #ED5EAF;
  --blue: #d6edff;
  --grey: #d9d9d9;
  --white: #FFFFFF;
  --black: #292929;
  --pinkContrast: #D31782;
}
*{
  margin: 0;
  padding: 0;
}
.image-description{
  background-color: var(--pink);
  margin-top: -5px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-bottom: 25px;
  
}
body{
  max-width: 100vw;
  overflow-x: hidden;
  background: var(--grey);
  font-family: "Roboto", sans-serif;
  margin: none;
  padding: none;
}

.loader{
  position: fixed;
}
.sectionCarouselMarques{
  background-color: var(--white);
}

.choisirOptiqueChatenaySection{
  margin-top: 20px;
  background-color: var(--white);
}
.contactezNousSection{
  margin-top: 20px;
  background-color: var(--white);
  display: flex;
  padding: 30px;
}
.header{
  margin: 0;
  padding: 0;;
  position: fixed;
  top:0;
  right:0;
  z-index: 999;
}
.header img{
  height: 100%;
  width: 100%;
}
.accueil{
  height: 100%;
}
.logo:hover{
  cursor: pointer;
}
.logo img{
  transform: scale(0.6);
}
.logo img:hover{
  transition: 0.10s;
  transform: scale(0.79);
}
.accueil{
  display: flex;
  background-image: url(./img/pretty-young-woman-in-sunglasses-smiling-2021-08-26-19-58-23-utc.webp);
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.blocText{
display: flex;
}
.accueilBlocText{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  justify-content: center;
  height: 100vh;
  width: 45vw;
  background-color: rgba(48, 48, 48, 0.7);
  text-align: left;
}
.accueilBlocText h1{
  line-height: 89.5px;
  font-size: 6rem;
  font-weight: medium 91px;
  margin-bottom: 10%;
}
.titreEtTexteetBoutton{
  display: flex;
}
.adressesEtTelBloc a {
  text-decoration: none;
  color: inherit;
}
.adressesEtTelBloc a:hover{
  transition: 0.5s;
  transform: scale(1.05);
}
.adressesEtTelBloc{
  font-size: 1.5rem;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.adresseEtTel{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.adressesEtTelBloc span{
  margin-right: 25px;
  scale: 1.5;
}

.telephones{
  display:flex;
  align-items: center;
  justify-content: center;
}
.paragrapheAccueil{
  margin-top: 50px;
  line-height: 35px;
  font-size: 20px;
}
.bouttonDecouvrir{
  margin-top: 60px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bouttonDecouvrir a{
  text-decoration: none;
  color: inherit;
}
.bouttonDecouvrir button{
  color: var(--black);
  font-size: 22px;
  display: flex;
  background-color: var(--pink);
  border: none;
  padding: 20px 60px 20px;
  border-radius: 10px 10px 10px 10px;
}
.bouttonDecouvrir button:hover{
  cursor: pointer;
  opacity: 0.9;
  transition: 0.25s;
}
.PositionBlocHoraire{
  position: absolute;
  right: 100px;
  bottom: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(48, 48, 48, 0.7);
  border-radius: 15px;
  padding: 10px;
  color: #a5dc92;
  box-shadow: var(--black) 3px 3px 3px;
  line-height: 40px;
}

.PositionBlocHoraire2{
  display: none;
  position: absolute;
  border-radius: 15px;
  color: #a5dc92;
  line-height: 40px;
}
.blocTextHeures{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bouttonHoraires{
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  display: flex;
  background-color: var(--pink);
  color: var(--black);
  border: none;
  padding: 10px 30px 10px;
  border-radius: 10px;
}
.blocHorairesTexte{
  display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.bouttonHoraires:hover{
  cursor: pointer;
  opacity: 0.9;
  transition: 0.25s;
}

.h2CarouselMarques{
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
}
.headerCarouselMarques{
  padding: 30px;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rondRose{
  position: relative;
  float: left;
  width: 100px;
  height: 100px;
  background: var(--pink);
  border-radius: 50%;
  background-color: var(--pink);
}
.rondRose2{
  position: relative;
  float: right;
  width: 100px;
  height: 100px;
  background: var(--pink);
  border-radius: 50%;
  background-color: var(--pink);
}
.rondRose3{
  position: relative;
  float: left;
  width: 130px;
  height: 130px;
  background: var(--pink);
  border-radius: 50%;
  background-color: var(--pink);
}
.carouselMarqueContainer{
  display: flex;
  justify-content: center;
}
.carouselMarqueContainer img{
  height: 100px;
  display: flex;
}
.nosMarquesContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.carouselMarques {
  overflow: hidden;
  width: 1000px;
}

.innerMarques {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carouselMarques-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--white);
}
.carouselMarques-item img{
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100% !important;  
  margin-left: 50px;
}
.carouselMarques-item img:hover{
  scale: 0.9;
  opacity: 0.8;
  transition: 0.25s;
  z-index: 999;
}

.indicators {
  display: flex;
  justify-content: center;
}

.indicators > button {
  margin: 5px;
  border: none;
  background: none;
  cursor: pointer;
}
.indicators > button:hover{
  scale: 1.1;
  opacity: 0.8;
}
.choisirOptiqueContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding:75px
}
.titreChoisirOptique{
  display: flex;
}
.choisirOptiqueh2{
  color: var(--black);
  font-size: 40px;
  font-weight: 400;
}
.paragrapheTextChoisir{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
.paragrapheTextChoisir span{
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
}
.paragraphe1{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rose{
  color: var(--pink);
  margin-left: 15px;
  font-size: 25px;
}

.liste{
  margin-left: 125px;
  color: var(--black);
  line-height: 25px;
}
.liste li{
  color: var(--black);
  font-weight: 400;
}
.docteur{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pink);
  padding: 15px;
  border-radius: 100%;
  font-size: 15px;
  color: var(--white);
}
.services{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pink);
  padding: 15px;
  border-radius: 100%;
  font-size: 15px;
  color: var(--white)
}
.glasses{
  background-color: var(--blue);
  padding: 15px;
  border-radius: 50%;
  font-size: 20px;
  color: var(--pink);
}
.galleriePhotoContainer{
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  height: auto;
}
.image-grid {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--white);
  padding: 15px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.image-container {
  width: calc(33.33% - 10px);
  margin: 5px;
}

.image:hover{
  opacity: 0.9;
}

.pagination {
  margin-top: 20px;
}
.page:hover{
  opacity: 0.8;
}
/* Gallery.css */

.galleriePhotoContainer {
  position: relative;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
}

.image-container {
  margin: 5px;
  position: relative;
}

.image {
  height: 25vh;
  object-fit: cover;
  cursor: pointer;
}

.pagination {
  margin-top: 20px;
}

.page,
.selected-page {
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #fff;
  cursor: pointer;
}
/* Ajoute un padding de 25px au composant */
.blocHoraires {
  padding: 25px;
}

/* Fait flotter le composant sur la page */
.tableauHoraires {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000; /* Assure que le composant flotte au-dessus du reste du contenu */
}

/* Ajoute un ombrage au composant flottant */
.tableauHoraires {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Style pour le bouton de fermeture du composant flottant */
.bouttonMenuHoraires {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
.selected-page {
  background-color: var(--pink); /* Utilisez votre couleur personnalisée ici */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-nav-button{
  margin: 15px;
  background-color: var(--pink);
  border: none;
  padding: 15px;
  border-radius: 25px;
  font-weight: 1000;
}
.modal-content .close{
  background-color: var(--pink);
  border-radius: 25px;
  padding: 5px;
  right: 5px;
  top: 5px;
  font-family: "Roboto", sans-serif;
}
.modal-nav-button:hover{
  cursor: pointer;
  background-color: var(--blue);
}
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  text-align: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.modal-image {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
  margin-top: 10px;
}

/* Add more styles as needed */
/* Gallery.css */

/* ... (autres styles restent inchangés) */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  text-align: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.modal-image {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
  margin-bottom: 10px; /* Ajout de marge en bas de l'image */
}

.title {
  font-size: 16px;
  margin-top: 10px;
  /* Ajoutez d'autres styles de titre selon vos préférences */
}

/* Add more styles as needed */

.page,
.selected-page {
  margin-right: 5px;
  padding: 15px 10px;
  border-radius: 25px;
  border: none;
  background-color: #fff;
  cursor: pointer;
}
.selected-page {
  background-color: var(--pink); /* Utilisez votre couleur personnalisée ici */
}
.selected-page:hover{
  opacity: 0.8;

}
@media only screen and (max-width: 880px) {
  .carouselMarques{
    width: 300px;
  }
}
.imagesContainerChoisir{
  display: flex;
}
.imagesLargeContainer{
  /* scale: 0.8; */
  right: -150px;
  padding: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 4px 27px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.imageNormContainer{
  /* scale: 0.8; */
  margin-top: -175px;
  padding: 8px;
  /* width: -moz-fit-content; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 4px 27px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
  left: 32px;
}
.voirConditions{
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.rondRoseContainer{
  position: relative;
  top: 300px;
  right: 180px;
}
.zoomed{
  border-radius: 25px;
}
.zoomed2{
  border-radius: 25px;
}
.nousTrouverContainer{
  padding: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.titreNousTrouverh2{
  color: var(--black);
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 30px;
}
.texte{
  margin-bottom: 50px;
}
.textP {
  color: var(--black);
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  word-break: keep-all;
  word-spacing: 100%;
  line-height: normal;
  width: 100%;
}
.bouttonRose{
  margin-top: 50px;
  padding: 20px 300px 20px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  background-color: var(--pink);
  color: var(--black);
}
.bouttonRose:hover{
  cursor: pointer;
  opacity: 0.9;
}
.tableauHoraires{
  background-color: var(--black);
  color: var();
  border-radius: 25px;
  transition: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9;
  border: none;
}
.tableauHoraires pre{
  font-family: 'Roboto', sans-serif;
}
.textHorairesPopup{
  align-items: flex-start;
}
.tableauHoraires h2{
  margin-bottom: 25px;
}
.tableauHoraires button{
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bouttonMenuHoraires{
  font-size: 18px;
  background-color: var(--pink);
  color: var(--black);
  width: 30%;
  padding: 10px;
  margin-top: 6px;
  border: none;
  border-radius: 10px 10px 10px 10px;
}
.bouttonMenuHoraires:hover{
  cursor: pointer;
  opacity: 0.9;
}
.mapLocation{
  display: flex;
  padding: 50px;
  background: linear-gradient(90deg, var(--white) 50%, var(--pink) 50% );
  border-radius: 0px 25px 25px 0px;
  flex:1;
}
.tableauHoraires li{
  list-style: none;
}

.formulaire{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
}
.formulaire input, textarea{
  font-family: "Roboto", sans-serif;
  padding: 15px;
  font-size: 25px;
  margin-top: 50px;
  border: none;
  border-bottom: 1px solid ;
  border-bottom-color: var(--black);
  width: 100%;
  height: 25px;
}
.input::placeholder, .textarea::placeholder{
  font-family: "Roboto", sans-serif;
}
.infosContact{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.telephoneInfos{
  margin-top: 25px;
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.telephoneInfos a {
  color: var(--pinkContrast);
  text-decoration: none;
}
.telephoneInfos a:hover{
  opacity: 0.8;
}
.mapContainer{
  flex: 1;
  border-radius: 25px;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.27);
  width: 100%;
}
.titreEtBouttons{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 900px;
  height: 100%;
  width: 50%
}
.infosContact span{
  margin-left: 10px;
  display: flex;
  justify-content: center;
  scale: 1.1;
}
.interieurInfosContact{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footerSection{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  background-color: var(--white);
  width: 100%;
}
.footerSection img{
  height: 75px;
}
.headerFooter{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.imageHeaderFooter{
  margin-right: 50px;
}
.imageHeaderFooter a:hover, .imageHeaderFooter img:hover{
  scale: 0.9;
  transition: 0.25s;
}
.headerFooterH5{
  border-right: 1px solid;
  padding-right: 50px;
  color: var(--black);
  margin-right: 50px;
  font-size: 25px;
}
.logoReseaux{
  display: flex;
}
.logoReseaux a{
  color: var(--black);
  text-decoration: none;
}
.logoReseaux a:hover{
  color: var(--pink);
  transition: 0.25s;
}
.facebook{
  margin-right: 15px;
  margin-left: -25px;
}
.footerListe{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.footerListe li{
  display: inline;
  justify-content: center;
  flex-direction: row;
}
.footerListe a{
  text-decoration: none;
  color: var(--black);
}
.footerListe a:hover{
  color: var(--pink);
  transition: 0.25s;
}
.footerDescription{
  margin-bottom: 25px;
  max-width: 900px;
}
.footerDescription p{
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
}
.copyrightFooter{
  color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.copyrightFooter a{
  text-decoration: none;
  color: var(--black);
}
.copyrightFooter a:hover{
  color: var(--pink);
  transition: 0.25s;
}
.elementListe1{
  padding-right: 25px;
  padding-left: 25px;
  border-right: 1px solid
}
.elementListe1 a{
  text-decoration: none;
  color: var(--black);
}
.elementListe2{
  padding-right: 25px;
  padding-left: 25px;
  border-right: 1px solid
}
.elementListe5{
  padding-right: 25px;
  padding-left: 25px;
  border-right: 1px solid

}
.elementListe4{
  padding-right: 25px;
  padding-left: 25px;
}
textarea{
  resize: vertical;
}

.bouttonRetour{
  padding: 25px;
  background-color: var(--white);
  height: 25px;
}
.bouttonRetour a{
  position: fixed;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--black);
}
.bouttonRetour a:hover{
  color: var(--pink);
  transition: 0.25s;
  margin-left: 5px;
}
.MentionsLegalesTexte{  
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 2em;
  margin-bottom: 2em;
}
.titreCatégoriesMentions{
  color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.titreMention{
  color: var(--black);
}
.titreCatégoriesMentions{
  color: var(--black);
  margin-top: 25px;
  margin-bottom: 25px;
}
.titreCatégoriesMentions h2{
  font-size: 35px;
  font-weight: 400;
}
.texteCatégoriesMentions a {
  text-decoration: none;
  color: var(--black);
}
.texteCatégoriesMentions p {
  color: var(--black);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 20px;
}
.titreEtTexteMentions{
  border-bottom: 1px solid;
}
.titreMention{
  font-size: 25px;
}
.titreGalleriePhotosPage{
  background-color: var(--white);
  padding: 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.sectionGalleriePhotos{
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: 25px;
}
.titreGalleriePhotos{
  margin-top: 25px;
  color: var(--black);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.titreGalleriePhotos h2{
  font-weight: normal 400;
  font-size: 40px;
}
.buttonUploadPhoto{
    display: flex;
    justify-items: center;
    align-items: center;
  }
  
.adminBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  .motDePasseForm{
    gap:30px;
  }
.blockMotDePasseContainer{
    color: var(--black);
    font-size: large;
    padding: 150px;
    border-radius: 25px;
    background-color: var(--white);
    max-width: 800px;
  max-height: 440px;
  overflow: scroll;
  min-width: 800px;
  min-height: 440px;
  }
  #password-input{
    font-family: 'Roboto', sans-serif;
    height: 25px;
    width: 250px;
    border-radius: 25px;
    border-width: 1;
    border-color: var(--black);
    padding: 10px;
    color: var(--pink);
    font-weight: 700;
  }
  .bouttonValider{
    padding: 10px;
    background-color: var(--pink);
    border-radius: 25px;
    border-width: 0;
    width: 100px;
  }
  .bouttonValider:hover{
    cursor: pointer;
    background-color: var(--blue)
  }
  #bouttonVisibilite{
    background-color: transparent;
    border:  none;
    color: var(--pink);
  }
  #bouttonVisibilite:hover{
    cursor: pointer;
    color: var(--grey);
    scale: 0.9
  }
  #bouttonVisibilite.activeButton {
    color: var(--blue);
  }
  #bouttonVisibilite.activeButton:hover{
    color: var(--grey);
    scale: 0.9;
  }
#boutton
  .bouttonValider:hover{
    background-color: var(--blue);
    color: var(--pink);
    cursor: pointer;
  }
  .uploaded-images-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin-top: 20px;

  }
  .pagination{
    display: flex;
    justify-content: space-around;
    width: 250px;
  }
  .pagination button{
    background-color: var(--pink);
    border: none;
    padding: 10px;
    border-radius: 30px;
  }
  .pagination button:hover{
    cursor: pointer;
    background-color: var(--blue);
  }
  .uploaded-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .uploaded-image {
    margin: 10px;
  }
  
  .uploaded-image img {
    max-width: 100%;
    max-height: 150px; /* Ajuste la hauteur maximale de l'image téléchargée */
  }
  .containeruploadFichier{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .containeruploadFichier img{
    max-width: 300px;
    min-width: 300px;
  }
  .motDePasseForm{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .blockMotDePasseContainer h5{
    padding: 25px;
    border: #292929 3px solid;
    border-radius: 25px;
  }
  .cancelButton {
    padding: 10px;
    background-color: #f00; /* Couleur de fond du bouton annuler */
    color: #fff; /* Couleur du texte du bouton annuler */
    border: none;
    border-radius: 5px;
    margin-top: 10px; /* Ajuste la marge en fonction de la disposition */
  }
  
.bouttonRetour2 a{
    border-radius: 25px 0px 0px 25px;
    background-color: var(--white);
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: inherit;
    transition: 0.25s;
  }
.bouttonRetour2 a:hover{
    color: var(--pink);
    background-color: var(--blue);
    transition: 0.25s;
  }
  .bouttonRetour2:hover{
    color: var(--black);
  }
 #password-input{
  display: flex;
 }
#labelMdp{
  display: flex;
  justify-content: center;
}
.bouttonRetour3{
  background-color: var(--grey);
  z-index: 999;
  position: fixed;
  display: flex;
}
.bouttonRetour3 a{
  padding: 5px;
  text-decoration: none;
  color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
}
.bouttonRetour3 a:hover{
  color: var(--pink);
  transition: 0.25s;
}
.gallerieDimageInterieur{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px;
}
.gallerieDimageInterieur h1{
  display: none;
}
.gallerieDimageInterieur img{
  box-shadow: 0px 4px 27px 4px rgba(0, 0, 0, 0.25);
}
.premiereImageGallerie{
  margin-bottom: 70px;
}
.troisiemeImageGallerie{
  display: none;
}
.imagesGalleriesContainer{

  display: flex;
  align-items: center;
}
.imagesGalleriesContainer img{
  border-radius: 25px;
}
.imagesHorizontalContainer{
  margin-right: 70px;
  display: flex;
  flex-direction: column;
}
.imagesHorizontalContainer img{
  /* min-width: 1000px;
  max-height: 400px; */
  object-fit: cover;
}
.imagesVerticales{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height:100%

}
.imagesVerticales img{
  object-fit: cover;
  /* width: 400px; */
  height: 100%; 
}
.imagesGalleriesContainer a:hover{
  opacity: 0.9;
  transition: 0.2s;
}

.pageNotFoundContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}
.erreur404{
  line-height: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.erreur404 span{
  scale: 2;
}

.loadingScreen{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}
.loaderDeux {
  border: 6px solid #f3f3f3; /* Light gray border */
  border-top: 6px solid var(--pink); /* Blue border for the spinning effect */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Spinning animation */
}
.image-buttons{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.image-buttons button{
  cursor: pointer;
  background-color: var(--pink);
  border: none;
  padding: 10px;
  border-radius: 25px;
}
.image-buttons button:hover{
  opacity: 0.9;
  transition: 0.5s;
  background-color: var(--blue);
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media screen and (max-width:576px) {
    .sectionCarouselMarques{
    display: none;
  }
  .header{
    display: none;
  }
  .imagesVerticales{
    display: none;
  }
  .gallerieDimageInterieur{
    padding: 50px;
  }
  .gallerieDimageInterieur h1{
    display: flex;
    margin-bottom: 25px;
  }
  .imagesHorizontalContainer{
    margin-right: 0;
  }
  .imagesHorizontalContainer img{
    min-width: 300px;
  }
  .premiereImageGallerie{
    margin-bottom: 25px;
  }
  .secondeImageGallerie{
    margin-top: none;
  }
  .troisiemeImageGallerie{
    display: flex;
    margin-top: 25px;
  }
  .accueil{
    background-position: 60%;
    background-size: cover;
  }
  .imagesContainerChoisir{
    display: none;
  }
  .adressesEtTelBloc{
    align-items: center;
  }
  .accueilBlocText{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 100%;
    padding: 50px;
  }
  .titreEtTextetBoutton{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .PositionBlocHoraire{
    display: none;
  }
  .PositionBlocHoraire2{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    color: #a5dc92;
    line-height: 40px;
    width: 70%;
    margin-top: 25px;
  }
  
  .bouttonDecouvrir{
    display: none;
  }
  .PositionBlocHoraire{
    display: none;
  }
  .PositionBlocHoraire2{
    display: flex;
  }
  .carouselMarques{
    width: 500px;
  }
  .textP{
    width: 75%;
  }
  .footerDescription{
    width: 75%;
  }
  footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  ul{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .nousTrouverContainer{
    flex-direction: column;
    align-items: center;
  }
  .titreEtBouttons{
    display: flex;
    flex-direction: column;
  }
  .mapLocation{
    display: none;
  }
  #contact{
    display: none;
  }
  .footerListe ul{
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-end;
   }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .accueil{
    background-position: 50%;
  }
  .accueilBlocText{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
  }
  .titreEtTextetBoutton{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .PositionBlocHoraire{
    display: none;
  }
  .PositionBlocHoraire2{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    color: #a5dc92;
    line-height: 40px;
    width: 70%;
    margin-top: 25px;
  }
  .gallerieDimageInterieur{
    padding: 50px;
  }
  .gallerieDimageInterieur h1{
    display: flex;
    margin-bottom: 25px;
  }
  .imagesHorizontalContainer{
    margin-right: 0;
  }
  .imagesHorizontalContainer img{
    min-width: 300px;
  }
  .premiereImageGallerie{
    margin-bottom: 25px;
  }
  .secondeImageGallerie{
    margin-top: none;
  }
  .troisiemeImageGallerie{
    display: flex;
    margin-top: 25px;
  }
  .imagesVerticales{
    display: none;
  }
  .choisirOptiqueContainer{
    width: 70%;
    padding-left: 10%;
  }
  .bouttonDecouvrir{
    display: none;
  }
  .PositionBlocHoraire{
    display: none;
  }
  .PositionBlocHoraire2{
    display: flex;
  }
  .carouselMarques{
    width: 500px;
  }
  .imagesContainerChoisir{
    display: none;
  }
  .titreEtBouttons{
    align-items: center;
    width: auto;
  }
  .textP{
    align-items: center;
  }
  .rondRoseContainer{
    display: none;
  }
  .footerDescription{
    width: 75%;
  }
  .nousTrouverContainer{
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .mapLocation{
    margin-top: 100px;
    margin-bottom: 50px;
  }
  footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .image-gallery{
    padding: 50px
    
   }
   .image-gallery-content .image-gallery-slide .image-gallery-image{
    height: 50vh;

   }
   .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal{
     background-color: white;
     padding: 15px;
     border-radius: 25px;
   }
   .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal img{
     height:100px;
     width: 100%;
     object-fit: cover;
   }

}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .accueil{
    background-position: 50%;
  }
  .accueilBlocText{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100%;
    padding-bottom: 25px;
  }
  .titreEtTextetBoutton{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .PositionBlocHoraire{
    display: none;
  }
  .PositionBlocHoraire2{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    color: #a5dc92;
    line-height: 40px;
    width: 70%;
    margin-top: 25px;
  }
  .imagesContainerChoisir{
    display: none;
  }
  .bouttonDecouvrir{
    display: none;
  }
  .h2CarouselMarques{
    text-align: center;
  }
  .carouselMarques{
    width: 500px;
  }
  .rondRoseContainer{
    display: none;
  }
  .sectionGalleriePhotos{
    align-items: center;
  }
  .choisirOptiqueContainer{
    width: 70%;
    padding-left: 10%;
  }
  .gallerieDimageInterieur{
    padding: 50px;
  }
  .gallerieDimageInterieur h1{
    display: flex;
    margin-bottom: 25px;
  }
  .imagesHorizontalContainer{
    margin-right: 0;
  }
  .imagesHorizontalContainer img{
    min-width: 300px;
  }
  .premiereImageGallerie{
    margin-bottom: 25px;
  }
  .secondeImageGallerie{
    margin-top: none;
  }
  .troisiemeImageGallerie{
    display: flex;
    margin-top: 25px;
  }

  .imagesVerticales{
    display: none;
  }
  .nousTrouverContainer{
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
  .formulaire input, textarea{
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
  }
  .titreEtBouttons{
    width: auto;
  }
.mapLocation{
  width:100%;
  min-height:10%;
  margin-top: 100px;
}
  .textP{
    width: 75%;
  }
  .footerDescription{
    width: 75%;
  }
  
  footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .image-gallery{
    padding: 50px;
    
   }
   .image-gallery-content .image-gallery-slide .image-gallery-image{
    height: 50vh;

   }
   .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal{
     background-color: white;
     padding: 15px;
     border-radius: 25px;
   }
   .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal img{
     height:100px;
     width: 100%;
     object-fit: cover;
   }
}
@media screen and (min-width: 992px) and (max-width: 1280px) {
  .header img{
    height: 75%;
  }
  .accueil{
    background-position: 100%;
  }
  .accueilBlocText{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
  }
  .bouttonDecouvrir{
    display: none;
  }
  .PositionBlocHoraire{
    display: none;
  }
  .h2CarouselMarques{
    text-align: center;
  }
  .carouselMarques{
    width: 500px;
  }
  .PositionBlocHoraire2{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    color: #a5dc92;
    line-height: 40px;
    width: 70%;
    margin-top: 25px;
  }
.accueilBlocText{
    max-width: 100%;
}
.formulaire input, textarea{
  text-align: center;
}
.titreEtTextetBoutton{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    scale: 0.8;
}
.titreEtBouttons{
  width: 75%;
  align-items: center;
}
  .gallerieDimageInterieur{
    padding: 50px;
  }
  .gallerieDimageInterieur h1{
    display: flex;
    margin-bottom: 25px;
  }
  .imagesHorizontalContainer{
    margin-right: 0;
  }
  .imagesHorizontalContainer img{
    min-width: 300px;
  }
  .premiereImageGallerie{
    margin-bottom: 25px;
  }
  .secondeImageGallerie{
    margin-top: none;
  }
  .troisiemeImageGallerie{
    display: flex;
    margin-top: 25px;
  }
.imagesVerticales{
  display: none;
}
.rondRoseContainer{
  display: none;
}
.choisirOptiqueContainer{
  padding-left: 10%;
}
.titreGalleriePhotos{
  margin-bottom: 50px;
}
.imagesContainerChoisir{
  display: none;
}
.nousTrouverContainer{
  flex-direction: column;
  align-items: center;
  padding: 25px 0px 25px 0px;
}
.mapLocation{
  width: 80%;
  margin-top: 25px;
  min-height: 20%;
}
.texteCatégoriesMentions{
  width: 70%;
}
.sectionGalleriePhotos{
  justify-content: center;
  align-items: center;
}
.mapContainer{
  flex: 1;
}
.image-gallery{
  padding: 50px;
  
 }
 .image-gallery-content .image-gallery-slide .image-gallery-image{
  height: 50vh;

 }
 .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal{
   background-color: white;
   padding: 15px;
   border-radius: 25px;
 }
 .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal img{
   height:100px;
   width: 100%;
   object-fit: cover;
 }
}
@media screen and (min-width: 1920px) {
  .accueil{
    background-size: 100%;
  }
}
